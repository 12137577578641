import React, { useState, useEffect } from 'react';

import Page from 'src/components/Page';
import {
  Button,
  Container,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { jobReducer, getJobs, getSkills, selectJob } from '../../redux/jobs';
import AddIcon from '@material-ui/icons/Add';
import { useSelector, useDispatch } from 'react-redux';

import { registerLocale } from 'react-datepicker';
import es from 'date-fns/locale/es';
import { projectReducer } from 'src/redux/projects';

import { getProjectsClassificationList } from '../../redux/projects';
import { useNavigate } from 'react-router';
import { useToasts } from 'react-toast-notifications';
import JobList from './jobList';

registerLocale('es', es);

const useStyles = makeStyles((theme) => ({
  listSection: {
    backgroundColor: 'inherit',
    margin: '5px',
    cursor: 'pointer',
  },

  ul: {
    backgroundColor: 'inherit',
    // padding: 0,
  },
  root: {
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  buttonGreen: {
    color: theme.palette.common.white,
    fontFamily: 'Roboto',
    backgroundColor: '#81B734',
    '&:hover': {
      backgroundColor: '#81B734',
    },
  },
  buttonPurple: {
    color: theme.palette.common.white,
    fontFamily: 'Roboto',
    backgroundColor: '#A179F2',
    '&:hover': {
      backgroundColor: '#A179F2',
    },
  },
  buttonPurpleOpacity: {
    borderRadius: '10px',
    padding: '.5rem 1rem ',
    textTransform: 'capitalize',
    color: '#986FEF',
    fontFamily: 'Roboto',
    backgroundColor: 'rgba(161, 120, 242, 0.20)',
    '&:hover': {
      backgroundColor: 'rgba(161, 120, 242, 0.20)',
    },
  },
  headerCellText: {
    fontSize: '11px',
    fontWeight: 500,
    fontFamily: 'Roboto',
    lineHeight: '13.75px',
  },
  headerCellText1: {
    fontSize: '10px',
    fontWeight: 600,
    fontFamily: 'Roboto',
    color: '#ADBAC3',
  },
  textField: {
    // marginLeft: theme.spacing(1),
    // marginRight: theme.spacing(1),
    width: '100%',
  },
  input: {
    display: 'none',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  hover: {
    ':hover': {
      backgroundColor: '#A179F2',
    },
  },
  [theme.breakpoints.down('sm')]: {
    title: {
      fontSize: '15px',
    },
  },
}));

function Jobs() {
  const classes = useStyles();
  const [modals, setModals] = useState({
    createNewJob: false,
    details: false,
    editMode: false,
    endJob: false,
  });

  //! INTANCES
  let dispatch = useDispatch();
  let navigate = useNavigate();
  const { addToast } = useToasts();

  //! SELECTORS
  const { jobsList, skillsList, createdBy } = useSelector(jobReducer);
  const { projectClassificationList } = useSelector(projectReducer);

  //! LIFE CYCLE
  useEffect(() => {
    if (!jobsList) {
      dispatch(getJobs());
    }
    dispatch(selectJob(null));

    if (!skillsList) {
      dispatch(getSkills());
    }

    if (projectClassificationList.length === 0) {
      dispatch(getProjectsClassificationList());
    }
  }, []);

  const handleCreateJob = () => {
    navigate('create');
  };
  //* 2. JSXS

  return (
    <Page className={classes.root} id="zoom-level" title="Jobs">
      <Container maxWidth={false}>
        <Grid
          container
          style={{
            background: modals.createNewJob ? '' : 'white',
            padding: '1em',
            borderRadius: '10px',
          }}
        >
          <Grid
            container
            // style={{ height: showCard === false ? '600px' : '' }}
          >
            <Grid
              container
              item
              xs={12}
              justify="space-between"
              alignItems="center"
            >
              <Grid item md={4} xs={5}>
                <Typography
                  style={{
                    fontFamily: 'Roboto',
                    fontSize: '18px',
                    fontWeight: 600,
                  }}
                  className={classes.title}
                >
                  Create New Job
                </Typography>
              </Grid>
              <Grid item xs={7} md={5} container justify="flex-end">
                <Button
                  onClick={handleCreateJob}
                  className={classes.buttonPurpleOpacity}
                  style={{ fontFamily: 'Roboto' }}
                  startIcon={<AddIcon />}
                >
                  Create New Job
                </Button>
              </Grid>
            </Grid>
            {jobsList?.length === 0 && !createdBy ? (
              <Grid
                container
                item
                xs={12}
                direction="column"
                justify="center"
                alignItems="center"
                style={{ height: '70vh' }}
              >
                <Grid>
                  <img
                    src="/static/images/bettamintImages/job.png"
                    alt="project"
                    style={{
                      width: '80px',
                      height: '90px',
                      textAlign: 'center',
                    }}
                  />
                </Grid>
                <Grid>
                  <Typography
                    style={{
                      color: '#ADBAC3',
                      fontSize: 12,
                      fontFamily: 'Roboto',
                    }}
                  >
                    You Dont Have a Job yet!
                  </Typography>
                </Grid>
                <Grid>
                  <Button
                    style={{
                      color: '#81B734',
                      fontSize: 12,
                      fontFamily: 'Roboto',
                    }}
                    onClick={handleCreateJob}
                  >
                    + Create New Job
                  </Button>
                </Grid>
              </Grid>
            ) : (
              <JobList />
            )}
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}

export default Jobs;
